import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

export enum SectionContentTypes {
  UseSectionHeaderMobile = styles.UseSectionHeaderMobile,
}
interface SectionContentProps {
  id?: string
  className?: string
  title?: string
  descText?: string
  hideTitleUnderline?: boolean
  titleIcon?: any
  titleIconAlt?: string
  showHeaderLine?: boolean
  showFooterLine?: boolean
  backgroundColor?: string
  style?: React.CSSProperties
  type?: SectionContentTypes
  useMobileHeader?: boolean
  hasMobilePadding?: boolean
  black?: boolean
  titleClassName?: string
  children?: any
  lang?: 'zh' | 'en'
}

const SectionContent: React.FC<SectionContentProps> = ({
  id,
  className,
  title,
  descText,
  hideTitleUnderline,
  titleIcon,
  titleIconAlt,
  showHeaderLine,
  showFooterLine,
  children,
  backgroundColor,
  style = {},
  type,
  useMobileHeader,
  hasMobilePadding,
  black,
  titleClassName,
  lang = 'zh',
}) => {
  const cnames = classnames(styles.SectionContent, className, type, {
    [styles.UseSectionHeaderMobile]: useMobileHeader,
    [styles.MobilePaddingWrap]: hasMobilePadding,
    [styles.Black]: black,
  })
  return (
    <section id={id} className={cnames} style={{ backgroundColor: backgroundColor, ...style }}>
      <article>
        {showHeaderLine && <hr className="header-line" />}
        {titleIcon && (
          <div className="title-icon-wrap">
            <img className="title-icon" src={titleIcon} alt={titleIconAlt || title || ''} />
          </div>
        )}
        {title && (
          <h2
            className={classnames(
              styles.SectionContentTitle,
              titleClassName,
              'section-content-title',
              hideTitleUnderline ? styles.hideUnderline : '',
            )}
          >
            {title}
          </h2>
        )}
        {descText && (
          <p lang={lang} className="section-content-desc">
            {descText}
          </p>
        )}
        {children}
        {showFooterLine && <hr className="footer-line" />}
      </article>
    </section>
  )
}

export default SectionContent

interface SectionHeaderMobileProps {
  className?: string
  title?: string
  right?: any
}
export const SectionHeaderMobile: React.FC<SectionHeaderMobileProps> = (props) => {
  const { className, title, right } = props
  return (
    <div className={classnames(styles.SectionHeaderMobile, 'section-header-mobile', className)}>
      <h2 className="section-header-mobile-title">{title}</h2>
      {right && <div className="section-header-mobile-right">{right}</div>}
    </div>
  )
}
